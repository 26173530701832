<!-- 用户编辑弹窗 -->
<template>
  <el-dialog
    :title="isUpdate ? '修改试题' : '添加试题'"
    :visible="visible"
    width="60%"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    @update:visible="updateVisible"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <!--      等级-->
      <el-form-item label="等级" prop="level_id">
        <el-select
          :span="18"
          v-model="ruleForm.level_id"
          placeholder="请选择等级"
        >
          <el-option
            :span="18"
            v-for="item in roleList"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="轨迹图" prop="flag">
        <el-select
          :span="18"
          v-model="ruleForm.flag"
          placeholder="请选择轨迹图"
        >
          <el-option
            :span="18"
            v-for="item in trajectory"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <!--分数-->
      <el-form-item label="分数" prop="score">
        <el-col :span="5">
          <el-input
            v-model.number="ruleForm.score"
            placeholder="请输入分数"
          ></el-input>
        </el-col>
      </el-form-item>
      <!--分数-->
      <el-form-item label="答题时长" prop="answer_time">
        <el-col :span="5">
          <el-input
            v-model="ruleForm.answer_time"
            oninput="value=value.replace(/[^\d.]/g,'')"
            placeholder="请输入答题时长"
          ></el-input>
          <span style="color: red">*单位：分钟</span>
        </el-col>
      </el-form-item>
      <!-- 编辑器 -->
      <el-form-item label="题干" prop="content">
        <el-col :span="18">
          <el-input
            v-model="ruleForm.content"
            placeholder="请输入题干,文字请用'/'隔开"
            :rows="4"
            type="textarea"
            @blur="contents"/>
        </el-col>
      </el-form-item>

      <!--选项-->

      <div
        class="form_add_problem"
        v-for="(item, index) in ruleForm.domains"
        :key="item.key"
      >
        <!--问题-->
        <el-form-item
          label="问题"
          :prop="'domains.' + index + '.problem'"
          :rules="{ required: true, message: '问题不能为空', trigger: 'blur' }"
        >
          <el-col :span="18">
            <el-input
              v-model="item.problem"
              placeholder="请输入问题"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item
          label="分数"
          :prop="'domains.' + index + '.score'"
          :rules="{ required: true, message: '分数不能为空', trigger: 'blur' }"
        >
          <el-col :span="18">
            <el-input v-model="item.score" placeholder="请输入分数"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="removeOptions(index)"
          >删除</el-button
          >
          <el-button type="primary" @click="addOptions(index)">新增</el-button>
        </el-form-item>
        <el-form-item
          v-for="(val, i) in item.options"
          :key="val.key"
          label="选项"
          :prop="'domains.' + index + '.options.' + i + '.chioptions'"
          :rules="{ required: true, message: '选项不能为空', trigger: 'blur' }"
        >
          <el-col :span="18">
            <el-input v-model="val.chioptions" placeholder=""></el-input>
          </el-col>
          <el-col :span="4" style="margin-left: 20px">
            <el-form-item
              :prop="'domains.' + index + '.options.' + i + '.radio'"
              :rules="{
                required: true,
                message: '请至少选择一个 ',
                trigger: 'blur',
              }"
            >
              <el-radio-group v-model="val.radio">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-button
          class="add_problem"
          type="primary"
          v-show="index === 0"
          @click="addProblem"
        >新增</el-button
        >
        <el-button
          style="margin-bottom: 10px"
          v-show="ruleForm.domains.length > 1"
          class="delete_problem"
          type="danger"
          @click="deleteProblem(index)"
        >删除
        </el-button>
      </div>



<!--      <div
        class="form_add_problem"
        v-for="(item, index) in ruleForm.domains"
        :key="item.key"
      >
        &lt;!&ndash; 类型 &ndash;&gt;
        <el-form-item label="类型" :prop="'domains.' + index + '.type'" :rules="{ required: true, message: '请选择类型', trigger: 'blur' }">
          <el-select :span="18" v-model="item.type" placeholder="请选择类型" @change="selectChange($event,index)">
            <el-option :span="18" label="类型一" :value="1"></el-option>
            <el-option :span="18" label="类型二" :value="2"></el-option>
          </el-select>
        </el-form-item>

        &lt;!&ndash;问题&ndash;&gt;
        <el-form-item
          label="问题"
          :prop="'domains.' + index + '.problem'"
          :rules="{ required: true, message: '问题不能为空', trigger: 'blur' }"
          v-if="item.type"
        >
          <div v-if="item.type == 1">
            <span class="problem" style="float: left;">小球内倒数第</span>
            <el-col :span="5">
              <el-select :span="18" v-model="item.problem" placeholder="请选择">
                <el-option v-for="(value, index) in characters" :span="18" :label="index+1" :value="(index+1)+''"></el-option>
              </el-select>


              &lt;!&ndash;<el-input
                :span="8"
                v-model="item.problem"
                placeholder="请输入圈数"
              ></el-input>&ndash;&gt;
            </el-col>
            <span class="problem">个显示的内容是</span>
          </div>

          <div v-if="item.type == 2">
            <span class="problem" style="float: left;">小球内</span>
            <el-col :span="5">
              <el-select :span="18" v-model="item.problem" placeholder="请选择">
                <el-option v-for="(value, index) in characters" :span="18" :label="value" :value="value"></el-option>
              </el-select>
            </el-col>
            <span class="problem">在第几个显示</span>
          </div>
        </el-form-item>

&lt;!&ndash;        <el-form-item
          label="答题时间"
          :prop="'domains.' + index + '.answer_time'"
          :rules="{
            required: true,
            message: '答题时间不能为空',
            trigger: 'blur'
          }"
        >
        </el-form-item>&ndash;&gt;
        <el-form-item
          label="分数"
          :prop="'domains.' + index + '.score'"
          :rules="{ required: true, message: '分数不能为空', trigger: 'blur' }"
        >
          <el-col :span="18">
            <el-input v-model="item.score" placeholder="请输入分数"></el-input>
          </el-col>
        </el-form-item>
        &lt;!&ndash; <el-form-item>
          <el-button type="danger" @click="removeOptions(index)"
            >删除</el-button
          >
          <el-button type="primary" @click="addOptions(index)">新增</el-button>
        </el-form-item>
        <el-form-item
          v-for="(val, i) in item.options"
          :key="val.key"
          label="选项"
          :prop="'domains.' + index + '.options.' + i + '.chioptions'"
          :rules="{ required: true, message: '选项不能为空', trigger: 'blur' }"
        >
          <el-col :span="18">
            <el-input v-model="val.chioptions" placeholder=""></el-input>
          </el-col>
          <el-col :span="4" style="margin-left: 20px">
            <el-form-item
              :prop="'domains.' + index + '.options.' + i + '.radio'"
              :rules="{
                required: true,
                message: '请至少选择一个 ',
                trigger: 'blur'
              }"
            >
              <el-radio-group v-model="val.radio">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form-item> &ndash;&gt;

        <el-button
          class="add_problem"
          type="primary"
          v-show="index === 0"
          @click="addProblem"
          >新增</el-button
        >
        <el-button
          style="margin-bottom: 10px"
          v-show="ruleForm.domains.length > 1"
          class="delete_problem"
          type="danger"
          @click="deleteProblem(index)"
          >删除
        </el-button>
      </div>-->
      <!--提交      -->
    </el-form>
    <div slot="footer">
      <el-button @click="resetForm">取消 </el-button>
      <el-button type="primary" @click="save" :loading="loading"
        >保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import TinymceEditor from "@/components/TinymceEditor";
export default {
  name: "UserEdit",
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 父组件方法，关闭弹框
    openEdit: Function
  },
  components: { TinymceEditor },
  data() {
    return {
      // 表单数据
      init: {},
      // 表单验证规则domains
      ruleForm: Object.assign(
        {
          level_id: "",
          score: "",
          answer_time: "",
          content: "",
          // 问题 - 选项
          /*domains: [
            {
              problem: "",
              score: "",
              // 类型
              type: ""
            }
          ]*/
          domains: [
            {
              problem: "",
              answer_time: "",
              score: "",
              // 选项
              options: [{ chioptions: "", radio: 0 }],
            },
          ],
        },
        this.data
      ),
      characters:[],
      // 等级下拉数据
      gradeSelection: [],
      rules: {
        score: [{ required: true, message: "请输入分值", trigger: "blur" }],
        level_id: [{ required: true, message: "请选择等级", trigger: "blur" }],
        answer_time: [
          { required: true, message: "请输入答题时长", trigger: "blur" }
        ],
        content: [{ required: true, message: "请输入题干", trigger: "blur" }],
        flag:[{ required: true, message: "请选择轨迹", trigger: "blur" }]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 角色列表
      roleList: [],
      //轨迹图
      trajectory: [
        {
          id: 1,
          name: "初级五点图"
        },
        {
          id: 2,
          name: "初级8字图"
        },
        {
          id: 3,
          name: "初级双三角图"
        },
        {
          id: 4,
          name: "中级纵向图"
        },
        {
          id: 5,
          name: "中级横向图"
        },
        {
          id: 6,
          name: "初级双菱图"
        },
        {
          id: 7,
          name: "高级方形图"
        },
        {
          id: 8,
          name: "高级彩球图"
        },
        {
          id: 9,
          name: "高级多角图"
        }
      ]
    };
  },
  //mounted
  watch: {
    //deep: true,
    data() {
      if (this.data) {
        this.$http["get"]("/Admin/eyemovementInfo/" + this.data.id).then(
          res => {
            if (res.data.data.domains) {
              this.data.domains = res.data.data.domains;
            } else {
              this.data.domains = [
                {
                  problem: "",
                  score: "",
                  answer_time: "",
                  type:""
                  // 选项
                  //options: [{ chioptions: "", radio: 0 }]
                }
              ];
            }
            this.ruleForm = Object.assign({}, this.data);
            this.isUpdate = true;
            this.contents();
          }
        );
      } else {
        this.ruleForm = Object.assign(
          {
            level_id: "",
            score: "",
            answer_time: "",
            content: "",
            // 问题 - 选项
            domains: [
              {
                problem: "",
                score: "",
                // 选项
                options: [{ chioptions: "", radio: 0 }]
              }
            ]
          },
          this.data
        );
        this.isUpdate = false;

      }
    }
  },
  mounted() {
    this.queryRoles(); // 查询角色列表
    // this.contents();
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.$http[this.isUpdate ? "put" : "post"](
            this.isUpdate
              ? "/Admin/eyemovement/" + this.ruleForm.id
              : "/Admin/eyemovement",
            this.ruleForm
          )
            .then(res => {
              this.loading = false;
              if (res.data.code === 200) {
                this.$message({ type: "success", message: res.data.msg });
                if (!this.isUpdate) {
                  this.ruleForm = {
                    level_id: "",
                    score: "",
                    answer_time: "",
                    content: "",
                    // 问题 - 选项
                    domains: [
                      {
                        problem: "",
                        score: "",
                        // 选项
                        options: [{ chioptions: "", radio: 0 }]
                      }
                    ]
                  };
                }
                this.updateVisible(false);
                this.$emit("done");
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch(e => {
              this.loading = false;
              this.$message.error(e.message);
            });
        } else {
          return false;
        }
      });
    },
    // 关闭
    resetForm(ruleForm) {
      // Object.assign(this.$data.ruleForm,this.$options.data().ruleForm)
      // this.$emit('update:visible', false);
      // 调用父组件方法 隐藏弹框
      this.openEdit(null);
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit("update:visible", value);
    },
    /* 查询等级列表 */
    queryRoles() {
      this.$http
        .get("/Admin/level")
        .then(res => {
          if (res.data.code === 200) {
            this.roleList = res.data.data;
            //console.log(this.roleList)
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          this.$message.error(e.message);
        });
    },
    addProblem() {
      this.ruleForm.domains.push({
        problem: "",
        options: [{ chioptions: "", radio: 0 }],
        rightselect: "",
        key: Date.now(),
        type: "" // 类型
      });
    },
    deleteProblem(index) {
      this.ruleForm.domains.splice(index, 1);
    },
    // 添加
    addOptions(index) {
      this.ruleForm.domains[index].options.push({
        chioptions: "",
        radio: 0,
        key: Date.now()
      });
    },
    // 删除
    removeOptions(index) {
      this.ruleForm.domains[index].options.splice(
        this.ruleForm.domains[index].options.length - 1,
        1
      );
    },
    contents(){
      var content  = this.ruleForm.content;
      if(content){
        var obj2 = content.split("/");
        this.characters = obj2;
      }

    },
    selectChange(event,num){
      this.ruleForm.domains[num].problem = '';
    }

  }
};
</script>

<style scoped>
.problem {
  display: inline-block;
  margin: 0 10px;
  font-weight: 700;
  font-size: 20px;
}
</style>
